import React from 'react';
import downloadIcon from "../../assets/icons/download.png";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useMutation, useQueryClient} from "react-query";
import {authorizedPost} from "../Creator/CreatorFunctions";
import {Loader} from "@mantine/core";
import okIcon from "../../assets/icons/ok.png";
import useTranslations from "../../hooks/useTranslations";
import {creatorActions} from "../../store/slice/gateCreatorSlice";
import {summaryBoxActions} from "../../store/slice/summarySlice";
import {uiActions} from "../../store/slice/uiSlice";
import editIcon from "../../assets/icons/edit.png";

const CustomHistoryItemFooter = ({item, gateDetailed}) => {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch()
    const accessToken = localStorage.getItem("token");
    const user = useSelector(state => state.user);
    const queryClient = useQueryClient();
    const createGateMutation = useMutation(authorizedPost, {
        onSuccess: (e) => {
            setTimeout(() => {
                createGateMutation.reset()
            }, 2000)
        },
        onError: (e) => {
            setTimeout(() => {
                createGateMutation.reset()
            }, 2000)
        }
    })
    const duplicateGateMutation = useMutation(authorizedPost, {
        onSuccess: (e) => {
            queryClient.invalidateQueries("order")
            queryClient.invalidateQueries("orderAll")
            setTimeout(() => {
                createGateMutation.reset()
            }, 2000)
        },
        onError: (e) => {
            setTimeout(() => {
                createGateMutation.reset()
            }, 2000)
        }
    })

    const getPDF = async (url, lang = 'pl') => {

        try {
            let httpClient = new XMLHttpRequest();
            let pdfLink = `${process.env.REACT_APP_API_URL}/api/${params.type}/${item.id}/${url}?lang=${lang}`;
            httpClient.open('get', pdfLink, true);
            httpClient.setRequestHeader("Authorization", `Bearer ${accessToken}`)
            httpClient.responseType = "blob";
            httpClient.onload = function () {
                const file = new Blob([httpClient.response], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = fileURL;
                link.download = `${params.type === "offers" ? item.offerNumber : item.orderNumber}.pdf`;
                link.click();
                // document.body.removeChild(link);
                URL.revokeObjectURL(fileURL);
            };
            httpClient.send();
        } catch (e) {
            console.log(e);
        }
    }
    const {
        historyDistributorCaclulation,
        historyFooterNetPrice,
        historyFooterDiscount,
        historyFooterNetDiscountPrice,
        historyDuplicateSuccess,
        historyClientCaclulation,
        historyLastEdit,
        historyFooterEmail,
        historyFooterPhone,
        downloadOnePdfButton,
        historyDuplicateButton,
        downloadTwoPdfButton,
        createOfferFromOrderButton,
        historyOrderFromOffer,
        historyOrderFromOfferError,
        editButton,
        language
    } = useTranslations();

    return (
        <div className="basket-right" style={{width: '100%', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div className="details" style={{width: '50%', display: 'flex', flexDirection: 'column'}}>
                {item?.offer?.id && user.isDistributor && params.type === 'orders' &&
                    <div>
                        <p>{historyDistributorCaclulation}</p>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div className="details__item" style={{width: '33%', margin: '12px 0'}}>
                                <strong>{historyFooterNetPrice}</strong>
                                <p>{(item?.orderData?.basePriceData?.regularNetPrice / 100).toFixed(2) + " zł" || "-"}</p>
                            </div>
                            <div className="details__item" style={{width: '33%', margin: '12px 0'}}>
                                <strong>{historyFooterDiscount}</strong>
                                <p>{Math.round(((1 - (item?.orderData?.basePriceData?.netPrice / item?.orderData?.basePriceData?.regularNetPrice))).toFixed(2) * 100) + "%" || "-"}</p>
                            </div>
                            <div className="details__item" style={{width: '33%', margin: '12px 0'}}>
                                <strong>{historyFooterNetDiscountPrice}</strong>
                                <p>{(item?.orderData?.basePriceData?.netPrice / 100).toFixed(2) + " zł" || "-"}</p>

                            </div>
                        </div>
                    </div>
                }
                {params.type === 'orders' &&
                    <div style={{marginTop: 12}}>
                        {item?.offer?.id && user.isDistributor && <p>{historyClientCaclulation}</p>}
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div className="details__item" style={{width: '33%', margin: '12px 0'}}>
                                <strong>{historyFooterNetPrice}</strong>
                                <p>{(item?.orderData?.priceData?.regularNetPrice / 100).toFixed(2) + " zł" || "-"}</p>
                            </div>
                            <div className="details__item" style={{width: '33%', margin: '12px 0'}}>
                                <strong>{historyFooterDiscount}</strong>
                                <p>{Math.round(((1 - (item?.orderData?.priceData?.netPrice / item?.orderData?.priceData?.regularNetPrice))).toFixed(2) * 100) + "%" || "-"}</p>
                            </div>
                            <div className="details__item" style={{width: '33%', margin: '12px 0'}}>
                                <strong>{historyFooterNetDiscountPrice}</strong>
                                <p>{params.type === "offers" ? (1 - item?.offerData?.priceData?.netPrice / 100).toFixed(2) + " zł" : (item?.orderData?.priceData?.netPrice / 100).toFixed(2) + " zł" || "-"}</p>

                            </div>
                        </div>
                    </div>
                }
                {params.type === 'offers' && <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="details__item" style={{width: '33%'}}>
                        <strong>{historyLastEdit}</strong>
                        <p>{new Date(item?.updatedAt).toLocaleDateString()}</p>
                    </div>
                    <div className="details__item" style={{width: '33%'}}>
                        <strong>{historyFooterEmail}</strong>
                        <p>{item?.client?.email}</p>
                    </div>
                    <div className="details__item" style={{width: '33%'}}>
                        <strong>{historyFooterPhone}</strong>
                        <p>{item?.client?.phone}</p>
                    </div>
                </div>}
            </div>
            <div className="buttons-box" style={{maxWidth: "50%", flexDirection: 'column'}}>
                <button
                    onClick={() => getPDF(params.type === "orders" ? 'client_get_pdf' : 'get_pdf', language)}
                    className="main-button">{downloadOnePdfButton}<img
                    src={downloadIcon} alt="background image"/>
                </button>
                {params.type === "orders" && item.offerNumber && user.isDistributor && <button
                    onClick={() => getPDF('get_pdf', language)}
                    className="main-button">{downloadTwoPdfButton}<img
                    src={downloadIcon} alt="background image"/>
                </button>}

                {params.type === "offers" && <button
                    onClick={() => {
                        createGateMutation.mutate({
                            url: "/api/orders/create_from_offer",
                            data: {
                                internalNumber: item.internalNumber,
                                orderNotes: item.orderNotes,
                                client: item.client.id,
                                offer: item.id,
                                receiverName: item.receiverName,
                                receiverCompany: item.receiverCompany,
                                receiverAddress: item.receiverAddress,
                                receiverZipCode: item.receiverZipCode,
                                receiverCity: item.receiverCity,
                                receiverState: item.receiverState,
                                receiverCountry: item.receiverCountry,
                                receiverPhone: item.receiverPhone,
                                receiverEmail: item.receiverEmail,
                            }
                        })
                    }}
                    className="main-button">
                    {createGateMutation.isLoading ?
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}><Loader
                            size={21}/></div> : <><span>{createOfferFromOrderButton}</span><img
                            src={okIcon} alt="background image"/></>}
                </button>}
                {params.type === "offers" && <button
                    onClick={() => {
                        duplicateGateMutation.mutate({
                            url: `/api/offers/${item?.id}/duplicate`,
                            data: {}
                        })
                    }}
                    className="main-button">
                    {duplicateGateMutation.isLoading ?
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}><Loader
                            size={21}/></div> : <><span>{historyDuplicateButton}</span><img
                            src={okIcon} alt="background image"/></>}
                </button>}
                {duplicateGateMutation.isSuccess &&
                    <p style={{textAlign: "center", color: "green"}}>{historyDuplicateSuccess}</p>}
                {createGateMutation.isSuccess &&
                    <p style={{textAlign: "center", color: "green"}}>{historyOrderFromOffer}</p>}
                {createGateMutation.isError &&
                    <p style={{textAlign: "center", color: "red"}}>{historyOrderFromOfferError}</p>}
            </div>
        </div>
    );
};

export default CustomHistoryItemFooter;
