import React, {useEffect, useMemo, useState} from 'react';
import arrowUpIcon from "../../../assets/icons/expanded.png";
import {motion} from "framer-motion";
import copyIcon from "../../../assets/icons/copy.png";
import editIcon from "../../../assets/icons/edit.png";
import deleteIcon from "../../../assets/icons/delete.png";
import {useDispatch, useSelector} from "react-redux";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    authorizedArrayPatchWithId,
    authorizedDeleteWithId,
    authorizedGet,
    authorizedPatchWithId,
    authorizedPost,
    authorizedPutWithId
} from "../../Creator/CreatorFunctions";
import {Loader, NumberInput, Select} from "@mantine/core";
import {useHistory, useParams} from "react-router-dom";
import {summaryBoxActions} from "../../../store/slice/summarySlice";
import {creatorActions} from "../../../store/slice/gateCreatorSlice";
import {uiActions} from "../../../store/slice/uiSlice";
import DiscountAmount from "./DiscountAmount";
import okIcon from "../../../assets/icons/ok.png";
import MontageCost from "./MontageCost";
import {useDebouncedValue} from "@mantine/hooks";
import useTranslations from "../../../hooks/useTranslations";

const CartItems = ({
                       changeAccessoryQtyMutation,
                       clickedItem,
                       showSummary,
                       setClickedItem,
                       cart,
                       isLoading,
                       client,
                       isOffer,
                       setPriceData
                   }) => {
    const user = useSelector(state => state.user);
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        cartSearchPlaceholder,
        cartItemsCompany,
        cartCreatedAt,
        cartName,
        cartStatus,
        cartNetCatalogPrice,
        cartNetPrice,
        cartCartStatusNew,
        collapseText,
        expandText,
        cartItemsKTMCode,
        cartItemsKTMDescription,
        cartItemsKTMQty,
        cartItemsGateType,
        cartItemsWidth,
        cartItemsHeight,
        cartItemsDepth,
        cartItemsAreaLeft,
        cartItemsAreaRight,
        cartItemsLintel,
        cartItemsLeading,
        cartItemsDrive,
        cartItemsRoofAngle,
        cartItemsGateAccessories,
        alignLeft,
        alignRight,
        alignTop,
        alignCenter,
        position,
        align,
        lack,
        cartItemsTax,
        cartItemsTaxPlaceholder,
        selectNoOptions,
        cartItemsEmail,
        cartItemsPhone,
        editButton,
        copyButton,
        deleteButton,
        cartItemsAccName,
        cartItemsPrice,
        language
    } = useTranslations();
    const params = useParams();
    const queryClient = useQueryClient();
    const creatorItem = useSelector(state => state.gateCreator);
    const [isEdit, setIsEdit] = useState(false);
    const [searchValue, setSearchedValue] = useState('')
    const [debauncedSearchValue] = useDebouncedValue(searchValue, 1000)
    const [cartItems, setCartItems] = useState(cart)
    const changeTaxMutation = useMutation(authorizedPatchWithId, {
        onSuccess: () => {
            queryClient.invalidateQueries('cart')
        }
    })

    const {data: clientCart} = useQuery(["cart", creatorItem, params.type], () => authorizedGet(`/api/${creatorItem.isOffer ? creatorItem.isOffer ? "offer_carts" : "carts" : params.type}`), {
        placeholderData: []
    })
    const {data: vatRates} = useQuery("vatRates", () => authorizedGet(`/api/vat_rates`), {
        placeholderData: []
    })
    const vatRatesOptions = useMemo(() => {
        if (!!vatRates?.length) {
            return vatRates?.map(vR => {
                return {value: vR.rate.toString(), label: `${vR.rate} %`}
            })
        } else {
            return []
        }

    }, [vatRates])

    const deleteCartItemMutation = useMutation(authorizedDeleteWithId, {
        onSuccess: (r) => {
            queryClient.invalidateQueries('cart')
        }
    });

    const addGateToCartMutation = useMutation(authorizedPatchWithId, {
        retry: 3,
    })
    const addAccessoriesToCartMutation = useMutation(authorizedPatchWithId, {
        retry: 3,
        onSuccess: () => {
            queryClient.invalidateQueries("cart")
        }
    })
    const changeDiscountMutation = useMutation(authorizedPatchWithId)
    const addGateAccessories = useMutation(authorizedArrayPatchWithId)
    const updateGateMutation = useMutation(authorizedPutWithId)
    const createGateMutation = useMutation(authorizedPost)

    useEffect(() => {
        const filteredItems = cartItems.filter(item => {
                return new Date(item?.createdAt).toLocaleDateString().toLowerCase().includes(debauncedSearchValue.toLowerCase())
            }
        )
        if (!!debauncedSearchValue.trim().length) {
            setCartItems(filteredItems)
        } else {
            setCartItems(cart)
        }
    }, [debauncedSearchValue, cart]);
    useEffect(() => {
        if (deleteCartItemMutation.isSuccess) {
            queryClient.invalidateQueries(params?.type)
        }
    }, [deleteCartItemMutation.isSuccess])
    return (
        <>
            <div className="custom-input-container">
                <input type="text" className="custom-input" placeholder={cartSearchPlaceholder}
                       onChange={e => setSearchedValue(e.target.value)}/>
            </div>
            <div className="content-items content-items__big" style={{background: "unset"}}>
                {isLoading && <Loader/>}
                {!showSummary && cartItems.length > 0 && cartItems.map((item, index) => {
                    console.log(item)
                    if (item?.gate?.id) {
                        return (
                            <div className="accordion-container" style={{background: 'white'}}>
                                <div className="content-accordion__item">
                                    <div>
                                        <p className="content-accordion__title">{cartItemsCompany}</p>
                                        <p className="">{client?.company}</p>
                                    </div>
                                    <div>
                                        <p className="content-accordion__title">{cartCreatedAt}</p>
                                        <p className="">{(new Date(item.createdAt)).toLocaleDateString()}</p>
                                    </div>
                                    <div>
                                        <p className="content-accordion__title">{params.type !== 'carts' ? cartNetCatalogPrice : cartNetPrice}</p>
                                        <p className="">{(item?.gate?.gateType?.name.toLowerCase().includes('przemysłowa') && !item?.gate?.leading?.priceType === 3) ? 'Cena na zapytanie' : (!isOffer ? user.isDistributor ? item?.basePriceData?.netRegularPrice / 100 : item?.priceData?.netRegularPrice / 100 : item?.priceData?.netPrice / 100).toFixed(2) + " zł"}</p>
                                    </div>
                                    <div>
                                        <p className="content-accordion__title">{cartName}</p>
                                        <p className="">{user?.userDisplayName}</p>
                                    </div>
                                    <div>
                                        <p className="content-accordion__title">{cartStatus}</p>
                                        <p className="">{cartCartStatusNew}</p>
                                    </div>
                                    <div className="accordion-icons flex" style={{alignItems: 'center'}}>
                                        <p>{clickedItem !== item.id ? expandText : collapseText}</p>
                                        <img
                                            className={`accordion-icons__expand ${clickedItem !== item.id ? "show" : ""}`}
                                            onClick={(e) => {
                                                setClickedItem(prev => {
                                                    if (prev === item.id) {
                                                        return null
                                                    } else {
                                                        return item.id
                                                    }
                                                })
                                            }}
                                            src={arrowUpIcon}
                                            alt="accordion icon"/>
                                    </div>
                                </div>
                                <motion.div
                                    initial={{opacity: 0, y: 5}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{
                                        delay: .2,
                                        default: {
                                            duration: .1
                                        }
                                    }
                                    }
                                    className="content-accordion-expand ">
                                    <div className="basket-container">
                                        <div className="basket-left">
                                            <div className="basket-box">
                                                <div className="basket-item">
                                                    <p className="basket-item__left">{cartItemsKTMCode}</p>
                                                    <p className="basket-item__right">{item?.gate?.ktmCode}</p>
                                                </div>
                                            </div>
                                            <div className="basket-box">
                                                <div className="basket-item">
                                                    <p className="basket-item__left">{cartItemsKTMDescription}</p>
                                                    <p className="basket-item__right">{item?.gate?.ktmCodeDescription}</p>
                                                </div>
                                            </div>
                                            <div className="basket-box">
                                                <div className="basket-item">
                                                    <p className="basket-item__left">{cartItemsKTMQty}</p>
                                                    {!isEdit &&
                                                        <div className="basket-item__right" style={{display: "flex"}}>
                                                            <p>{item?.quantity}</p>
                                                            <img
                                                                onClick={() => {
                                                                    setClickedItem(item.id)
                                                                    setIsEdit(true)
                                                                }
                                                                }
                                                                src={editIcon}
                                                                alt="edit icon"/>
                                                        </div>}
                                                    {isEdit && <motion.div initial={{opacity: 0}}
                                                                           style={{
                                                                               display: "flex",
                                                                               alignItems: "center"
                                                                           }}
                                                                           animate={{opacity: 1}}
                                                                           transition={{
                                                                               delay: .2,
                                                                               default: {
                                                                                   duration: .1
                                                                               }
                                                                           }
                                                                           }
                                                                           className="basket-item__right">
                                                        <p><NumberInput
                                                            defaultValue={item?.quantity}
                                                            min={1}
                                                            size="xs"
                                                            style={{maxWidth: 50}}
                                                            onChange={(e) => {
                                                                changeAccessoryQtyMutation.mutate({
                                                                    url: `/api/${isOffer ? 'offer_cart_items' : 'cart_items'}/${item.id}/change_quantity`,
                                                                    data: {
                                                                        quantity: e
                                                                    }
                                                                }, {
                                                                    onSuccess: d => {
                                                                        setPriceData(d)
                                                                    }
                                                                })
                                                            }
                                                            }
                                                        /></p>
                                                        <img
                                                            onClick={() => {
                                                                setIsEdit(false)
                                                            }
                                                            }
                                                            src={okIcon}
                                                            alt="edit icon"/>
                                                    </motion.div>}
                                                </div>
                                            </div>
                                            {clickedItem === item.id && <>
                                                <div className="basket-box">
                                                    <div className="basket-item">
                                                        <p className="basket-item__left">{cartItemsGateType}</p>
                                                        <p className="basket-item__right">{language === 'pl ' ? item?.gate?.gateType?.name : item?.gate?.gateType?.translations ? item?.gate?.gateType?.translations[language]?.name : '-' }</p>
                                                    </div>
                                                </div>
                                                <div className="basket-box">
                                                    <div className="basket-item">
                                                        <p className="basket-item__left">{cartItemsWidth}</p>
                                                        <p className="basket-item__right">{item?.gate?.width?.value} mm</p>
                                                    </div>
                                                    <div className="basket-item">
                                                        <p className="basket-item__left">{cartItemsHeight}</p>
                                                        <p className="basket-item__right">{item?.gate?.height?.value} mm</p>
                                                    </div>
                                                    <div className="basket-item">
                                                        <p className="basket-item__left">{cartItemsDepth}</p>
                                                        <p className="basket-item__right">{item?.gate?.depth} mm</p>
                                                    </div>
                                                    <div className="basket-item">
                                                        <p className="basket-item__left">{cartItemsAreaLeft}</p>
                                                        <p className="basket-item__right">{item?.gate?.rightArea} mm</p>
                                                    </div>
                                                    <div className="basket-item">
                                                        <p className="basket-item__left">{cartItemsAreaRight}</p>
                                                        <p className="basket-item__right">{item?.gate?.leftArea} mm</p>
                                                    </div>
                                                    <div className="basket-item">
                                                        <p className="basket-item__left">{cartItemsLintel}</p>
                                                        <p className="basket-item__right">{item?.gate?.lintel.value} mm</p>
                                                    </div>
                                                    {item?.gate?.drive && <div className="basket-item">
                                                        <p className="basket-item__left">{cartItemsDrive}</p>
                                                        <p className="basket-item__right">{language === 'pl' ? item?.gate?.drive?.name : item?.gate?.drive?.translations ? item?.gate?.drive?.translations[language]?.name : '-'}</p>
                                                    </div>}
                                                    <div className="basket-item">
                                                        <p className="basket-item__left">{cartItemsLeading}</p>
                                                        <p className="basket-item__right">{language === 'pl' ? item?.gate?.leading?.name : item?.gate?.leading?.translations ? item?.gate?.leading?.translations[language]?.name : '-'}</p>
                                                    </div>
                                                    {item?.gate?.roofAngle && <div className="basket-item">
                                                        <p className="basket-item__left">{cartItemsRoofAngle}</p>
                                                        <p className="basket-item__right">{item?.gate?.roofAngle}</p>
                                                    </div>}
                                                    {item?.gate?.componentItems.map(ci => {
                                                        return (
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">{language === 'pl' ? ci.component.name : ci?.component?.translations ? ci?.component?.translations[language].name : '-'}</p>
                                                                <p className="basket-item__right">{language === 'pl' ? ci.name : ci?.translations ? ci?.translations[language].name : '-'}</p>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="basket-box">
                                                    <div className="basket-item">
                                                        <p className="basket-item__left">{cartItemsGateAccessories}</p>
                                                    </div>
                                                    {item?.gate?.gateAccessories?.map(i => {
                                                        return (
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">{language === 'pl' ? i?.gateAccessory?.category?.name : i?.gateAccessory?.category?.translations ? i?.gateAccessory?.category?.translations[language]?.name : '-'}</p>
                                                                {i.position ? <p className="basket-item__right">
                                                                        {position + " " + i.position + ` , ${align} `}
                                                                        {i.alignment === 1 &&
                                                                            <span>{alignRight}</span> || i.alignment === 2 &&
                                                                            <span>{alignLeft}</span> || i.alignment === 3 &&
                                                                            <span>{alignCenter}</span> || i.alignment === 4 &&
                                                                            <span>{alignTop}</span>}
                                                                    </p> :
                                                                    <p className="basket-item__right">{language === 'pl' ? i?.gateAccessory?.name : i?.gateAccessory?.translations ?i?.gateAccessory?.translations[language]?.name : '-'} {i?.gateAccessory?.name.toLocaleLowerCase() !== 'brak' ? `(x${i.quantity})` : ''}</p>}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                {isOffer && <div className="basket-box">
                                                    <DiscountAmount id={item?.id} item={item} url={'offer_cart_items'}/>
                                                    <MontageCost id={item?.id} item={item}/>
                                                    <div className="basket-item">
                                                        <p className="basket-item__left">{cartItemsTax}</p>
                                                        <p className="basket-item__right">
                                                            <Select
                                                                placeholder={cartItemsTaxPlaceholder}
                                                                data={vatRatesOptions}
                                                                onChange={(e) => {
                                                                    changeTaxMutation.mutate({
                                                                        url: `/api/${isOffer ? 'offer_cart_items' : 'cart_items'}/${item.id}/change_tax`,
                                                                        data: {
                                                                            tax: +e
                                                                        }
                                                                    })
                                                                }
                                                                }
                                                                defaultValue={item.tax.toString()}
                                                                radius="xs"
                                                                size="md"
                                                                maxDropdownHeight={400}
                                                                nothingFound={selectNoOptions}
                                                            />
                                                        </p>
                                                    </div>
                                                </div>}
                                            </>}

                                        </div>
                                        {clickedItem === item.id && <div className="basket-right">
                                            <div className="details">
                                                <div className="details__item">
                                                    <strong>{cartItemsEmail}</strong>
                                                    <p>{user?.email}</p>
                                                </div>
                                                <div className="details__item">
                                                    <strong>{cartItemsPhone}</strong>
                                                    <p>{user?.userPhone || "-"}</p>
                                                </div>
                                            </div>
                                            <div className="buttons-box">
                                                <button
                                                    onClick={() => {
                                                        if (item?.drives?.length > 0) {
                                                            dispatch(creatorActions.updateCreator({
                                                                ...item.gate,
                                                                showDescription: false,
                                                                showVisualization: false,
                                                                isOffer,
                                                            }))
                                                            // dispatch(creatorActions.updateItemDetailExtraAccessories(item.gate.gateAccessories))
                                                            dispatch(summaryBoxActions.updateNumberOfSteps(5))
                                                            dispatch(uiActions.editModeOn(null))
                                                            dispatch(summaryBoxActions.updateCurrentStep(5))
                                                            history.push('/creator/step/5')
                                                        } else {
                                                            dispatch(creatorActions.updateCreator({
                                                                ...item.gate,
                                                                showDescription: false,
                                                                showVisualization: false,
                                                                isOffer,
                                                            }))
                                                            // dispatch(creatorActions.updateItemDetailExtraAccessories(item.gate.gateAccessories))
                                                            dispatch(summaryBoxActions.updateNumberOfSteps(4))
                                                            dispatch(uiActions.editModeOn(null))
                                                            dispatch(summaryBoxActions.updateCurrentStep(4))
                                                            history.push('/creator/step/4')
                                                        }
                                                    }
                                                    }
                                                    className="main-button">{editButton}<img
                                                    src={editIcon} alt="background image"/>
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        const accessories = item.gate.gateAccessories.map(i => {
                                                            return {
                                                                data: i.gateAccessory
                                                            }
                                                        });
                                                        createGateMutation.mutate({
                                                                url: "/api/gates",
                                                                data: {
                                                                    gateType: item.gate.gateType.id,
                                                                    client: user.userId
                                                                }
                                                            }, {
                                                                onSuccess: (data) => {
                                                                    updateGateMutation.mutate({
                                                                        url: "/api/gates",
                                                                        id: data.id,
                                                                        data: {
                                                                            ...item.gate,
                                                                            id: data.id,
                                                                            gateType: item?.gate?.gateType?.id,
                                                                            width: item?.gate?.width?.id,
                                                                            height: item.gate?.height?.id,
                                                                            lintel: item.gate?.lintel?.id,
                                                                            drive: item?.gate?.drive?.id,
                                                                            leading: item?.gate?.leading?.id,
                                                                            componentItems: item.gate.componentItems.map(e => e.id),
                                                                            render: item?.gate?.render?.id || null,
                                                                        }
                                                                    }, {
                                                                        onSuccess: (gateData) => {
                                                                            if (gateData.gateType) {
                                                                                addGateToCartMutation.mutate({
                                                                                    url: `/api/${isOffer ? "offer_carts" : "carts"}/${clientCart[0].id}/add_gate`,
                                                                                    data: {
                                                                                        gate: gateData.id
                                                                                    }
                                                                                }, {
                                                                                    onSuccess: d => {
                                                                                        addGateAccessories.mutate({
                                                                                            url: `/api/gates/${data.id}/add_gate_accessory`,
                                                                                            items: accessories
                                                                                        }, {
                                                                                            onSuccess: () => {
                                                                                                if (params.type === 'offer_carts') {
                                                                                                    changeDiscountMutation.mutate({
                                                                                                        url: `/api/cart_items/${d.items[0].id}/change_montage_cost`,
                                                                                                        data: {
                                                                                                            montageCost: +item.montageCost,
                                                                                                        }
                                                                                                    }, {
                                                                                                        onSuccess: () => {
                                                                                                            changeDiscountMutation.mutate({
                                                                                                                url: `/api/offer_cart_items/${d.items[0].id}/change_discount`,
                                                                                                                data: {
                                                                                                                    discountAmount: +item.discountAmount,
                                                                                                                }
                                                                                                            }, {
                                                                                                                onSuccess: () => {
                                                                                                                    queryClient.invalidateQueries("cart")
                                                                                                                }
                                                                                                            })
                                                                                                        }
                                                                                                    })
                                                                                                } else {
                                                                                                    queryClient.invalidateQueries("cart")
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                })
                                                                            }
                                                                        }
                                                                    })

                                                                    dispatch(creatorActions.updateCreator({
                                                                        id: data.id,
                                                                        isOffer: params.type === "offer_carts",
                                                                        isOrder: params.type === "carts"
                                                                    }))

                                                                }
                                                            }
                                                        )

                                                        dispatch(creatorActions.updateCreator(item.gate))
                                                    }
                                                    }
                                                    className="main-button">
                                                    {createGateMutation.isLoading || updateGateMutation.isLoading || addGateToCartMutation.isLoading || addGateAccessories.isLoading || changeDiscountMutation.isLoading ?
                                                        <Loader size={24}/> : <>{copyButton}<img
                                                            src={copyIcon} alt="background image"/></>}
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        deleteCartItemMutation.mutate({
                                                            url: `/api/${params.type === "offer_carts" ? "offer_" : ""}cart_items`,
                                                            id: item.id
                                                        })
                                                    }}
                                                    className="main-button"
                                                >
                                                    {deleteCartItemMutation.isLoading ? <Loader size={24}/> : <>{deleteButton}
                                                        <img src={deleteIcon} alt="background image"/></>}
                                                </button>
                                            </div>
                                        </div>}
                                    </div>
                                </motion.div>
                            </div>
                        )
                    }
                    if (item?.accessory?.id) {
                        return (
                            <div className="accordion-container" style={{background: 'white'}}>
                                <div className="content-accordion__item">
                                    <div>
                                        <p className="content-accordion__title">{cartItemsCompany}</p>
                                        <p className="">{client?.company}</p>
                                    </div>
                                    <div>
                                        <p className="content-accordion__title">{cartCreatedAt}</p>
                                        <p className="">{(new Date(item?.createdAt)).toLocaleDateString()}</p>
                                    </div>
                                    {<div>
                                        <p className="content-accordion__title">{cartNetPrice}</p>
                                        <p
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            transition={{
                                                delay: .2,
                                                default: {
                                                    duration: .1
                                                }
                                            }
                                            }
                                            className="">{changeAccessoryQtyMutation.isLoading ?
                                            <Loader
                                                size={12}/> : Math.round((item?.accessory?.price / 100 * item?.quantity)) + " zł"}</p>
                                    </div>}
                                    <div>
                                        <p className="content-accordion__title">{cartName}</p>
                                        <p className="">{user?.userDisplayName}</p>
                                    </div>
                                    <div>
                                        <p className="content-accordion__title">{cartStatus}</p>
                                        <p className="">{cartCartStatusNew}</p>
                                    </div>
                                    <div className="accordion-icons flex" style={{alignItems: 'center'}}>
                                        <p>{clickedItem !== item.id ? expandText : collapseText}</p>
                                        <img
                                            className={`accordion-icons__expand ${clickedItem !== item.id ? "show" : ""}`}
                                            onClick={(e) => {
                                                setClickedItem(prev => {
                                                    if (prev === item.id) {
                                                        return null
                                                    } else {
                                                        return item.id
                                                    }
                                                })
                                            }} src={arrowUpIcon}
                                            alt="accordion icon"/>
                                    </div>
                                </div>
                                <motion.div
                                    initial={{opacity: 0, y: 5}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{
                                        delay: .2,
                                        default: {
                                            duration: .1
                                        }
                                    }
                                    }
                                    className="content-accordion-expand ">
                                    <div className="basket-container">
                                        <div className="basket-left">
                                            <div className="basket-box">
                                                <div className="basket-item">
                                                    <p className="basket-item__left">{cartItemsKTMCode}</p>
                                                    <p className="basket-item__right">{item?.accessory?.ktm}</p>
                                                </div>
                                                <div className="basket-item">
                                                    <p className="basket-item__left">{cartItemsKTMDescription}</p>
                                                    <p className="basket-item__right">{item?.accessory?.ktmDescription}</p>
                                                </div>
                                                <div className="basket-item">
                                                    <p className="basket-item__left">{cartItemsKTMQty}</p>
                                                    {!isEdit &&
                                                        <div className="basket-item__right" style={{display: "flex"}}>
                                                            <p>{item?.quantity}</p>
                                                            <img
                                                                onClick={() => {
                                                                    setIsEdit(true)
                                                                }
                                                                }
                                                                src={editIcon}
                                                                alt="edit icon"/>
                                                        </div>}
                                                    {isEdit && <motion.div initial={{opacity: 0}}
                                                                           style={{
                                                                               display: "flex",
                                                                               alignItems: "center"
                                                                           }}
                                                                           animate={{opacity: 1}}
                                                                           transition={{
                                                                               delay: .2,
                                                                               default: {
                                                                                   duration: .1
                                                                               }
                                                                           }
                                                                           }
                                                                           className="basket-item__right">
                                                        <p><NumberInput
                                                            defaultValue={item?.quantity}
                                                            min={1}
                                                            size="xs"
                                                            style={{maxWidth: 50}}
                                                            onChange={(e) => {
                                                                changeAccessoryQtyMutation.mutate({
                                                                    url: `/api/${isOffer ? "offer_cart_accessories" : "cart_accessories"}/${item.id}/change_quantity`,
                                                                    data: {
                                                                        quantity: e
                                                                    }
                                                                })
                                                            }
                                                            }
                                                        /></p>
                                                        <img
                                                            onClick={() => {
                                                                setIsEdit(false)
                                                            }
                                                            }
                                                            src={okIcon}
                                                            alt="edit icon"/>
                                                    </motion.div>}
                                                </div>
                                                {clickedItem === item.id && <>
                                                    <div className="basket-item">
                                                        <p className="basket-item__left">{cartItemsAccName}</p>
                                                        <p className="basket-item__right">{item?.accessory?.name}</p>
                                                    </div>
                                                    <div className="basket-item">
                                                        <p className="basket-item__left">{cartItemsPrice}</p>
                                                        <p className="basket-item__right">{item?.accessory?.price / 100 + " zł"}</p>
                                                    </div>
                                                </>}

                                            </div>

                                            {isOffer && clickedItem === item.id && <div className="basket-box">
                                                <DiscountAmount id={item?.id} item={item}
                                                                url={'offer_cart_accessories'}/>
                                                <div className="basket-item">
                                                    <p className="basket-item__left">{cartItemsTax}</p>
                                                    <p className="basket-item__right">
                                                        <Select
                                                            placeholder={cartItemsTaxPlaceholder}
                                                            data={vatRatesOptions}
                                                            onChange={(e) => {
                                                                changeTaxMutation.mutate({
                                                                    url: `/api/${isOffer ? 'offer_cart_accessories' : 'cart_accessories'}/${item.id}/change_tax`,
                                                                    data: {
                                                                        tax: +e
                                                                    }
                                                                })
                                                            }
                                                            }
                                                            defaultValue={item.tax.toString()}
                                                            radius="xs"
                                                            size="md"
                                                            maxDropdownHeight={400}
                                                            nothingFound={selectNoOptions}
                                                        />
                                                    </p>
                                                </div>
                                            </div>}
                                        </div>
                                        {clickedItem === item.id && <div className="basket-right">
                                            <div className="basket-item">

                                                <p className="basket-item__left">{item?.name}</p>
                                                <img
                                                    src={`${process.env.REACT_APP_API_UPLOADS + item?.accessory?.image?.contentUrl}`}
                                                    alt="" style={{
                                                    width: 100,
                                                    height: 100,
                                                    objectFit: "cover",
                                                    objectPosition: "center"
                                                }}/>
                                            </div>
                                            <div className="details">
                                                <div className="details__item">
                                                    <strong>{cartItemsEmail}</strong>
                                                    <p>{user?.email}</p>
                                                </div>
                                                <div className="details__item">
                                                    <strong>{cartItemsPhone}</strong>
                                                    <p>{user?.userPhone || "-"}</p>
                                                </div>
                                            </div>
                                            <div className="buttons-box">
                                                <button
                                                    onClick={() => {
                                                        addAccessoriesToCartMutation.mutate({
                                                            url: `/api/${isOffer ? "offer_carts" : "carts"}/${clientCart[0].id}/add_accessory`,
                                                            data: {
                                                                accessory: item.accessory.id,
                                                                quantity: item.accessory.quantity
                                                            }
                                                        })
                                                    }
                                                    }
                                                    className="main-button">{copyButton}<img
                                                    src={copyIcon} alt="background image"/>
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        dispatch(creatorActions.deleteItemExtraAccessories(item))
                                                        deleteCartItemMutation.mutate({
                                                            url: `/api/${params.type === "offer_carts" ? "offer_" : ""}cart_accessories`,
                                                            id: item.id
                                                        }, {
                                                            onSuccess: (r) => {
                                                                queryClient.invalidateQueries(params?.type)
                                                            }
                                                        })

                                                    }}
                                                    className="main-button"
                                                >
                                                    {deleteButton}
                                                    <img src={deleteIcon} alt="background image"/>
                                                </button>
                                            </div>
                                        </div>}
                                    </div>
                                </motion.div>
                            </div>
                        )
                    }
                })}
            </div>
        </>
    );
};

export default CartItems;
