import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {Loader} from "@mantine/core";
import {Document, Page} from "react-pdf";
import useTranslations from "../../../hooks/useTranslations";

const SummaryBoxPdf = () => {
    const creatorItem = useSelector(state => state.gateCreator);
    const accesoriesIds = useMemo(() => {
        if (creatorItem?.accessories) {
            let ids = creatorItem.accessories.flatMap(a =>
                Array(a.quantity).fill(a.id)
            ).toString();
            return '?accessories=' + ids;
        } else {
            return '';
        }
    }, [creatorItem]);
    const {language} = useTranslations();

    const [numPages, setNumPages] = useState();
    const [file, setFile] = useState(undefined)
    const [width, setWidth]   = useState(window.innerWidth >= 1070 ? ((window.innerWidth ) * 50) / 100 : window.innerWidth - 40);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const getPDF = async () => {
        try {
            let httpClient = new XMLHttpRequest();
            let pdfLink = `${process.env.REACT_APP_API_URL}/api/gates/${creatorItem.id}/get_pdf${accesoriesIds}&lang=${language}`;
            httpClient.open('get', pdfLink, true);
            httpClient.responseType = "blob";
            httpClient.onload = function () {
                const file = new Blob([httpClient.response], {type: 'application/pdf'});
                setFile(file)
            };
            httpClient.send();
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (creatorItem?.id) {
            getPDF()
        }
    }, [creatorItem?.id])

    return (
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess} noData={<Loader/>} loading={<Loader/>}>
            {Array.apply(null, Array(numPages))
                .map((x, i)=>i+1)
                .map(page => <Page pageNumber={page} width={width}  scale={1} renderAnnotationLayer={false}
                                   renderTextLayer={false}/>)}
        </Document>
    );
};

export default SummaryBoxPdf;
