import React, {useState} from 'react';
import addIcon from "../../../assets/icons/add.png";
import "../../../styles/global/_global.scss";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {authorizedDeleteWithId, authorizedGetById} from "../../Creator/CreatorFunctions";
import {Loader, Modal} from '@mantine/core';
import {Pencil, Trash} from 'tabler-icons-react';
import useTranslations from "../../../hooks/useTranslations";

const ClientList = () => {
    const user = useSelector(state => state.user);
    const queryClient = useQueryClient();
    const content = queryClient.getQueryData("contents");
    const [open, setOpen] = useState(false)
    const [client, setClient] = useState(undefined)
    const {
        data: distributor,
        isLoading
    } = useQuery(["client", user], () => authorizedGetById("/api/clients", user.userId), {
        keepPreviousData: true
    })

    const deleteClientMutation = useMutation(authorizedDeleteWithId, {
        onSuccess: () => {
            queryClient.invalidateQueries(["client", user])
            setTimeout(() => {
                deleteClientMutation.reset()
            }, 2000)
        }
    })
    const {
        clientListTitle,
        clientListDescription,
        addClientButton,
        clientListInternalNumber,
        cartItemsCompany,
        userDataName,
        userDataSurname,
        userDataPhone,
        clientListCompanyNumber,
        newClientCompanyEmail,
        cartStatus,
        clientListDistributor,
        clientListActions,
        clientListStatusNotVerified,
        clientListStatusActive,
        clientListStatusNotActive,
        clientsListDeleteClient,
        clientsListDeleteConfrimation,
        clientsListDeleteCancel
    } = useTranslations();

    return (
        <>
            <h2 className="dashboard-title">{clientListTitle}</h2>
            <div style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                gap: 20,
                marginTop: -40,
                flexWrap: 'wrap'
            }}>
                <p>{clientListDescription}</p>
                <Link to='/client/new' className="button-2">{addClientButton} <img src={addIcon}
                                                                                   alt="background image"/></Link>
            </div>

            <div className="client-container">
                {isLoading && <div style={{width: "100%", textAlign: "center", padding: 20}}><Loader/></div>}
                {!isLoading && distributor?.clients && <div className="custom-table custom-table__clients">
                    <table>
                        <thead>
                        <tr>
                            <th>{clientListInternalNumber}</th>
                            <th>{cartItemsCompany}</th>
                            <th>{clientListCompanyNumber}</th>
                            <th>{userDataName}</th>
                            <th>{userDataSurname}</th>
                            <th>{newClientCompanyEmail}</th>
                            <th>{cartStatus}</th>
                            <th>{userDataPhone}</th>
                            <th>{clientListDistributor}</th>
                            <th>{clientListActions}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {distributor?.clients.map(client => {
                            return (<tr key={client.id}>
                                <td>{client.internalNumber ? client.internalNumber : "-"}</td>
                                <td>{client.company ? client.company : "-"}</td>
                                <td>{client.vatNumber ? client.vatNumber : "-"}</td>
                                <td>{client.name}</td>
                                <td>{client.surname}</td>
                                <td>{client.email}</td>
                                <td>{client.isActive && !client.isVerified ? clientListStatusNotVerified : client.isActive ? clientListStatusActive : clientListStatusNotActive}</td>
                                <td>{client.phone ? client.phone : "-"}</td>
                                <td>{distributor?.company || "Brak"}</td>
                                <td>
                                    <div className="table-icons">
                                        {/*<button>*/}
                                        {/*    <Eye color={'#7D94C8'}/>*/}
                                        {/*</button>*/}

                                        <Link to={`/client/${client.id}`}>
                                            <button>
                                                <Pencil color={'#7D94C8'}/>
                                            </button>
                                        </Link>
                                        <button
                                            onClick={() => {
                                                setClient(client)
                                                setOpen(true)
                                            }}
                                        >
                                            {deleteClientMutation.isLoading && deleteClientMutation.variables.id === client.id ?
                                                <Loader size={14}/> : <Trash color={"red"}/>}
                                        </button>
                                    </div>
                                </td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>}
            </div>
            <Modal opened={open} onClose={() => {
                setClient(undefined)
                setOpen(false)
            }} centered>
                <h2 style={{fontSize: 16, textAlign: 'center'}}>{clientsListDeleteClient} {client ? client.name + " " + client.surname : ''}</h2>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 50}}>
                    <button
                        onClick={() => deleteClientMutation.mutate({
                            url: "/api/clients",
                            id: client.id
                        }, {
                            onSuccess: () => {
                                setClient(undefined)
                                setOpen(false)
                            }
                        })}
                        className={'button-2'} style={{background: '#FF0000'}}>{deleteClientMutation.isLoading ?
                        <Loader size={22} color={'white'}/> :
                        clientsListDeleteConfrimation}</button>
                    <button
                        onClick={() => {
                            setClient(undefined)
                            setOpen(false)
                        }}
                        className={'button-2'}>{clientsListDeleteCancel}
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default ClientList;
