import React, {useCallback, useEffect, useMemo, useState} from 'react';
import img from "../../../assets/car.png";
import {creatorActions} from "../../../store/slice/gateCreatorSlice";
import {useDispatch} from "react-redux";
import {motion} from "framer-motion";
import {useMutation, useQuery} from "react-query";
import {Loader, Select} from '@mantine/core';
import {authorizedGet, authorizedPut, authorizedPutWithId} from "../CreatorFunctions";
import useTranslations from "../../../hooks/useTranslations";

const CreatorItemDetail = ({creatorItem}) => {
    const dispatch = useDispatch();
    const {
        creatorItemDetailsWidth,
        creatorItemDetailsWidthPlaceholder,
        creatorItemDetailsHeightPlaceholder,
        creatorItemDetailsHeight,
        creatorItemDetailsLintelPlaceholder,
        creatorItemDetailsLintel,
        creatorItemDetailsLeadingPlaceholder,
        creatorItemDetailsLeading,
        creatorItemDetailsDepthPlaceholder,
        creatorItemDetailsDepth,
        creatorItemDetailsRightAreaPlaceholder,
        creatorItemDetailsRightArea,
        creatorItemDetailsLeftAreaPlaceholder,
        creatorItemDetailsLeftArea,
        language,
        creatorItemDetailsMinDepth,
        creatorItemDetailsDriveValidation,
        cartItemsRoofAngle,
        creatorItemsMinHeightToDriveGates,
        creatorItemsRangeValidation,
        selectNoOptions,
        creatorItemsIndustrailMinValidation1,
        creatorItemsIndustrailMinValidation2,
        creatorItemsIndustrailMinValidation3,
        creatorItemsMinLintelValidation1,
        creatorItemsMinLintelValidation2
    } = useTranslations();

    const updateGateMutation = useMutation(authorizedPutWithId)

    const {data: optionWidths} = useQuery("gateTypeWidths", () => authorizedGet(`/api/gate_types/${creatorItem?.gateType?.id}/widths`), {
        placeholderData: []
    })
    const optionWidthsSorted = optionWidths?.widths?.sort((a, b) => a.value - b.value) || []
    const {data: optionHeights} = useQuery("gateTypeHeights", () => authorizedGet(`/api/gate_types/${creatorItem?.gateType?.id}/heights`), {
        placeholderData: []
    })
    const optionHeightsSorted = optionHeights?.heights?.sort((a, b) => a.value - b.value) || []

    const {data: optionLintel} = useQuery("lintels", () => authorizedGet("/api/lintels?pagination=false"), {
        placeholderData: []
    })

    const {data: chosenGateTypeAllDrives} = useQuery("chosenGateTypeDrives", () => authorizedGet(`/api/gate_types/${creatorItem?.gateType?.id}/drive_groups`), {
        placeholderData: []
    })
    const chosenGateTypeDrives = chosenGateTypeAllDrives?.driveGroups?.map(dg => dg.drives).flat(1).filter(dg => dg.minHeight !== 99999999);
    const chosenGateTypeDrive = chosenGateTypeDrives?.filter(cGD => cGD?.leadings?.find(l => l.id === creatorItem?.leading?.id))
    const chosenGateMinLeft = chosenGateTypeDrive ? Math.min(...chosenGateTypeDrive?.map(cD => cD.leftArea)) : undefined;
    const chosenGateMinRight = chosenGateTypeDrive ? Math.min(...chosenGateTypeDrive?.map(cD => cD.rightArea)) : undefined;
    const driveGroups = chosenGateTypeAllDrives.driveGroups || [];
    const formulaValues = ['Ho', 'So']
    const formulaSigns = ['+', '-', '/', '*']

    const findNumber = (lintelFormula) => {
        const numberRegex = /[-+]?\d+(\.\d+)?/g;
        const number = lintelFormula.match(numberRegex);

        if (number && number.length > 0) {
            return parseFloat(number[0]);
        } else {
            return 0;
        }
    }
    const filteredLeading = useCallback(() => {
        return driveGroups
            .map(dG => dG.drives.filter(dr =>
                (dr.minHeight <= creatorItem?.lintel?.value && dr.maxHeight >= creatorItem?.lintel?.value) &&
                (dr?.minTotalHeight <= creatorItem?.height?.value && dr?.maxTotalHeight >= creatorItem?.height?.value))
                .map(drive => drive.leadings.map(leading => leading))
            ).flat(2)
            .filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i)
            .filter(leading => {
                if (leading.lintelFormula) {
                    const formulaValue = formulaValues.find(fV => leading.lintelFormula.includes(fV));
                    const formulaSign = formulaSigns.find(fV => leading.lintelFormula.includes(fV));
                    const formulaNumber = findNumber(leading.lintelFormula);

                    let calculatedValue;
                    if (formulaValue === 'Ho') {
                        switch (formulaSign) {
                            case '+':
                                calculatedValue = creatorItem?.height?.value + formulaNumber;
                                break;
                            case '-':
                                calculatedValue = creatorItem?.height?.value - formulaNumber;
                                break;
                            case '*':
                                calculatedValue = creatorItem?.height?.value * formulaNumber;
                                break;
                            case '/':
                                calculatedValue = creatorItem?.height?.value / formulaNumber;
                                break;
                            default:
                                calculatedValue = creatorItem?.height?.value;
                                break;
                        }
                        return creatorItem?.lintel?.value >= calculatedValue;
                    } else if (formulaValue === 'So') {
                        switch (formulaSign) {
                            case '+':
                                calculatedValue = creatorItem?.width?.value + formulaNumber;
                                break;
                            case '-':
                                calculatedValue = creatorItem?.width?.value - formulaNumber;
                                break;
                            case '*':
                                calculatedValue = creatorItem?.width?.value * formulaNumber;
                                break;
                            case '/':
                                calculatedValue = creatorItem?.width?.value / formulaNumber;
                                break;
                            default:
                                calculatedValue = creatorItem?.width?.value;
                                break;
                        }
                        return creatorItem?.lintel?.value >= calculatedValue;
                    }
                }
                return true;
            })
            .filter(f => !f.isHidden);
    }, [creatorItem]);

    const [filteredDriveGroups, setFilteredDriveGroups] = useState([])
    const gateSurface = (creatorItem?.height?.value * creatorItem?.width?.value) / 1000000;
    const minDepth = useMemo(() => {
        if (!creatorItem?.height?.value) return 0;
        // if (!creatorItem?.chosenGate?.isAutomatic && !creatorItem?.chosenGate?.isIndustrial) {
        //     return creatorItem?.height?.value + 460;
        // }definedMinDepth
        if (creatorItem?.chosenGate && creatorItem?.leading?.minDepth) {
            return creatorItem?.leading?.minDepth;
        }
        // if (creatorItem?.chosenGate?.isIndustrial) {
        //     return creatorItem?.height?.value + 650;
        // }
        return 0;
    }, [creatorItem?.height?.value, creatorItem?.leading?.id, creatorItem?.lintel?.id])

    const minLintel = useMemo(() => {
        if (creatorItem?.chosenGate && !!creatorItem?.leading?.lintelFormula) {
            return undefined;
        } else if (creatorItem?.chosenGate && !!creatorItem?.leading?.minLintel) {
            return creatorItem?.leading?.minLintel;
        }
    }, [creatorItem?.height?.value, creatorItem?.leading?.id, creatorItem?.lintel?.id])
    const leadingLintelMin = useMemo(()=> creatorItem?.leading?.minLintel, [creatorItem])
    const leadingLintelMax = useMemo(()=> creatorItem?.leading?.maxLintel, [creatorItem])
    const lintelValidation = minLintel ? creatorItem?.lintel?.value >= minLintel && !!creatorItem?.width?.id && !!creatorItem?.height?.id && !!creatorItem?.lintel?.id : !!creatorItem?.width?.id && !!creatorItem?.height?.id && !!creatorItem?.lintel?.id;
    const lintelLeadingValidation = lintelValidation ? (leadingLintelMin && leadingLintelMax) ? creatorItem?.lintel.value >= leadingLintelMin && creatorItem?.lintel.value <= leadingLintelMax :  true : false;
    const leadingValidation = lintelValidation && lintelLeadingValidation ? lintelLeadingValidation : creatorItem?.leading?.id;
    const areaRightValidation = creatorItem?.rightArea >= chosenGateMinRight;
    const areaLeftValidation = creatorItem?.leftArea >= chosenGateMinLeft;
    const sizeValidation = areaRightValidation && areaLeftValidation;
    const automaticGateValidation = creatorItem?.chosenGate?.isAutomatic && lintelValidation && creatorItem?.lintel?.value >= 160;
    const depthValidation = creatorItem?.depth >= minDepth && leadingValidation;

    useEffect(() => {
        dispatch(creatorActions.updateCreator({sizeValidation: sizeValidation}))
    }, [sizeValidation])

    useEffect(() => {
        if (minDepth > 0 && creatorItem?.depth <= minDepth) {
            dispatch(creatorActions.updateCreator({depth: +minDepth}))
        }
    }, [minDepth, creatorItem?.lintel?.id, creatorItem?.leading?.id])
    useEffect(() => {
        const filteredDrives = [];
        for (const driveGroup of driveGroups) {
            for (const drive of driveGroup.drives) {
                for (const leading of drive.leadings) {
                    if (leading?.id !== creatorItem?.leading?.id) continue
                    if (!(drive?.minHeight <= creatorItem?.lintel?.value && drive?.maxHeight >= creatorItem?.lintel?.value)) continue
                    if (!(drive?.minTotalHeight <= creatorItem?.height?.value && drive?.maxTotalHeight >= creatorItem?.height?.value)) continue
                    if (!(drive?.depth <= creatorItem?.depth && drive?.leftArea <= creatorItem?.leftArea && drive?.rightArea <= creatorItem?.rightArea && drive?.maximalSurface >= gateSurface)) continue
                    filteredDrives.push(drive)
                }
            }
        }
        setFilteredDriveGroups(filteredDrives)
    }, [driveGroups, gateSurface, creatorItem])
    const driveValidation = filteredDriveGroups?.length > 0 && creatorItem?.rightArea > 0 && creatorItem?.leftArea > 0 || filteredDriveGroups?.length === 1 && filteredDriveGroups[0]?.name?.toLowerCase() === 'brak';
    useEffect(() => {
        if (filteredLeading()?.length === 0 && sizeValidation) {
            dispatch(creatorActions.updateCreator({leading: null}))
        }
    }, [filteredLeading, creatorItem])

    useEffect(() => {
        dispatch(creatorActions.updateCreator({driveValidation: driveValidation}))
    }, [driveValidation])

    const saveGateMutation = useMutation(authorizedPut)
    return (
        <motion.div
            initial={{opacity: 0, y: 5}}
            animate={{opacity: 1, y: 0}}
            transition={{
                default: {
                    duration: .1
                }
            }
            }
            className="creator-container flex gap-40">
            <div className="content-form content-form__medium">
                <form action="" className="form dashboard-form show">
                    <div className="form--inp">
                        <label htmlFor="holeWidth">{creatorItemDetailsWidth}</label>
                        <Select
                            placeholder={creatorItemDetailsWidthPlaceholder}
                            data={optionWidthsSorted?.map(opt => {
                                return {value: opt.id, label: opt.value}
                            })}
                            disabled={creatorItem?.isEdit && updateGateMutation.isLoading}
                            rightSection={creatorItem?.isEdit && updateGateMutation.isLoading && <Loader size={12}/>}
                            value={creatorItem?.width?.id}
                            searchable
                            radius="xs"
                            size="md"
                            onChange={data => {
                                    if (creatorItem?.leading?.id) {
                                        updateGateMutation.mutate({
                                            url: '/api/gates',
                                            data: {
                                                width: data,
                                                offer: creatorItem?.isEdit ? creatorItem.offerId : null,
                                            },
                                            id: creatorItem.id
                                        }, {
                                            onSuccess: (d) => {
                                                dispatch(creatorActions.updateCreator(
                                                    {
                                                        ...d,
                                                        lintel: undefined,
                                                        leading: undefined,
                                                        depth: undefined,
                                                    }))
                                            }
                                        })
                                    } else {
                                        updateGateMutation.mutate({
                                            url: '/api/gates',
                                            data: {
                                                width: data,
                                                offer: creatorItem?.isEdit ? creatorItem.offerId : null,
                                            },
                                            id: creatorItem.id
                                        }, {
                                            onSuccess: (d) => {
                                                dispatch(creatorActions.updateCreator(d))
                                            }
                                        })
                                    }
                            }}
                            maxDropdownHeight={400}
                            nothingFound={selectNoOptions}
                            filter={(value, item) => item.label.toString().includes(value.toString())}
                        />
                    </div>
                    <div className="form--inp">
                        <label htmlFor="holeHeight">{creatorItemDetailsHeight}</label>
                        <Select
                            placeholder={creatorItemDetailsHeightPlaceholder}
                            data={optionHeightsSorted?.map(opt => {
                                return {value: opt.id, label: opt.value}
                            })}
                            disabled={creatorItem?.isEdit && updateGateMutation.isLoading}
                            rightSection={creatorItem?.isEdit && updateGateMutation.isLoading && <Loader size={12}/>}
                            value={creatorItem?.height?.id}
                            searchable
                            radius="xs"
                            size="md"
                            onChange={data => {
                                if (creatorItem?.leading?.id) {
                                    updateGateMutation.mutate({
                                        url: '/api/gates',
                                        data: {
                                            height: data,
                                            offer: creatorItem?.isEdit ? creatorItem.offerId : null,
                                        },
                                        id: creatorItem.id
                                    }, {
                                        onSuccess: (d) => {
                                            dispatch(creatorActions.updateCreator(
                                                {
                                                    ...d,
                                                    lintel: undefined,
                                                    leading: undefined,
                                                    depth: undefined,
                                                }))
                                        }
                                    })
                                } else {
                                    updateGateMutation.mutate({
                                        url: '/api/gates',
                                        data: {
                                            height: data,
                                            offer: creatorItem?.isEdit ? creatorItem.offerId : null,
                                        },
                                        id: creatorItem.id
                                    }, {
                                        onSuccess: (d) => {
                                            dispatch(creatorActions.updateCreator(d))
                                        }
                                    })
                                }
                            }}
                            maxDropdownHeight={400}
                            nothingFound={selectNoOptions}
                            filter={(value, item) => item.label.toString().includes(value.toString())}
                        />
                    </div>
                    <div className="form--inp">
                        <label htmlFor="lintel">{creatorItemDetailsLintel}</label>
                        <Select
                            placeholder={creatorItemDetailsLintelPlaceholder}
                            data={optionLintel.sort((a, b) => a.value - b.value).map(opt => {
                                return {value: opt.id, label: opt.value}
                            })}
                            disabled={creatorItem?.isEdit && updateGateMutation.isLoading}
                            rightSection={creatorItem?.isEdit && updateGateMutation.isLoading && <Loader size={12}/>}
                            value={creatorItem?.lintel?.id}
                            searchable
                            radius="xs"
                            size="md"
                            onChange={data => {
                                if (creatorItem?.leading?.id) {
                                    updateGateMutation.mutate({
                                        url: '/api/gates',
                                        data: {
                                            lintel: data,
                                            offer: creatorItem?.isEdit ? creatorItem.offerId : null,
                                        },
                                        id: creatorItem.id
                                    }, {
                                        onSuccess: (d) => {
                                            dispatch(creatorActions.updateCreator(
                                                {
                                                    ...d,
                                                    leading: undefined,
                                                    depth: undefined,
                                                }))
                                        }
                                    })
                                } else {
                                    updateGateMutation.mutate({
                                        url: '/api/gates',
                                        data: {
                                            lintel: data,
                                            offer: creatorItem?.isEdit ? creatorItem.offerId : null,
                                        },
                                        id: creatorItem.id
                                    }, {
                                        onSuccess: (d) => {
                                            dispatch(creatorActions.updateCreator(d))
                                        }
                                    })
                                }
                            }}
                            maxDropdownHeight={400}
                            nothingFound={selectNoOptions}
                            filter={(value, item) => item.label.toString().includes(value.toString())}
                        />
                    </div>
                    <div className="form--inp">
                        <label htmlFor="creatorInput7">{creatorItemDetailsLeading}</label>
                        <Select
                            disabled={creatorItem?.isEdit && updateGateMutation.isLoading}
                            rightSection={creatorItem?.isEdit && updateGateMutation.isLoading && <Loader size={12}/>}
                            placeholder={creatorItemDetailsLeadingPlaceholder}
                            data={filteredLeading()?.map(opt => {
                                return {value: opt.id, label: language === 'pl' ? opt.name : opt?.translations ? opt?.translations[language].name : '-'}
                            }) || []}
                            disabled={creatorItem?.chosenGate?.isAutomatic ? !automaticGateValidation : !lintelValidation}
                            styles={(theme) => ({
                                item: {
                                    fontSize: 14,
                                    // applies styles to selected item
                                    '&[data-selected]': {
                                        '&, &:hover': {
                                            backgroundColor: "red",
                                            color: theme.colorScheme === 'dark' ? theme.white : theme.colors.teal[9],
                                        },
                                    },

                                    // applies styles to hovered item (with mouse or keyboard)
                                    '&[data-hovered]': {},
                                },
                            })}
                            onChange={data => {
                                updateGateMutation.mutate({
                                    url: '/api/gates',
                                    data: {
                                        leading: data,
                                        offer: creatorItem?.isEdit ? creatorItem.offerId : null,
                                    },
                                    id: creatorItem.id
                                }, {
                                    onSuccess: (d) => {
                                        dispatch(creatorActions.updateCreator(d))
                                    }
                                })
                            }}
                            value={creatorItem?.leading?.id || ''}
                            searchable
                            radius="xs"
                            size="md"
                            maxDropdownHeight={400}
                            nothingFound={selectNoOptions}
                            filter={(value, item) => item?.label?.toString().toLowerCase().includes(value.toString().toLowerCase())}
                        />
                    </div>
                    <div className="form--inp">
                        <label htmlFor="depth">{creatorItemDetailsDepth}</label>
                        <input
                            onChange={data => {
                                dispatch(creatorActions.updateCreator({depth: +data.target.value}))
                            }}
                            style={creatorItem.depth > 0 && creatorItem?.leading?.minDepth > creatorItem.depth ? {borderColor: "red"} : null}
                            disabled={creatorItem?.isEdit && updateGateMutation.isLoading || !leadingValidation}
                            value={leadingValidation && creatorItem.depth > 0 ? creatorItem.depth : ''}
                            type="number"
                            min={minDepth}
                            id="depth"
                            placeholder={minDepth ? `${creatorItemDetailsDepthPlaceholder}:${minDepth}mm` : creatorItemDetailsDepthPlaceholder}
                            required/>
                    </div>
                    <div className="form--inp">
                        <label htmlFor="rightArea">{creatorItemDetailsRightArea}</label>
                        <input
                            style={creatorItem.rightArea > 0 && !areaRightValidation ? {borderColor: "red"} : null}
                            onChange={data => {
                                dispatch(creatorActions.updateCreator({rightArea: +data.target.value}))
                            }}
                            disabled={creatorItem?.isEdit && updateGateMutation.isLoading || !depthValidation}
                            value={creatorItem.rightArea > 0 ? creatorItem.rightArea : ''}
                            type="number"
                            id="rightArea"
                            placeholder={chosenGateMinLeft && chosenGateMinLeft !== Infinity ? `${creatorItemDetailsRightAreaPlaceholder} -${chosenGateMinRight}mm` : `${creatorItemDetailsRightAreaPlaceholder}`}
                            required/>
                    </div>
                    <div className="form--inp">
                        <label htmlFor="leftArea">{creatorItemDetailsLeftArea}</label>
                        <input
                            onChange={data => {
                                dispatch(creatorActions.updateCreator({leftArea: +data.target.value}))
                            }}
                            style={creatorItem.leftArea > 0 && !areaLeftValidation ? {borderColor: "red"} : null}
                            disabled={creatorItem?.isEdit && updateGateMutation.isLoading || !depthValidation}
                            value={creatorItem.leftArea > 0 ? creatorItem.leftArea : ''}
                            type="number"
                            id="leftArea"
                            placeholder={chosenGateMinLeft && chosenGateMinLeft !== Infinity ? `${creatorItemDetailsLeftAreaPlaceholder} -${chosenGateMinLeft}mm` : `${creatorItemDetailsLeftAreaPlaceholder}`}
                            required/>
                    </div>

                    {creatorItem?.leading?.roofAngleRequired && <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        className="form--inp">
                        <label htmlFor="rightArea">{cartItemsRoofAngle} [&alpha;][stopnie &deg;]</label>
                        <input
                            defaultValue={creatorItem.roofAngle > 0 ? creatorItem.roofAngle : null}
                            onChange={data => {
                                dispatch(creatorActions.updateCreator({roofAngle: +data.target.value}))
                            }}
                            type="number"
                            id="roofAngle"
                            placeholder={cartItemsRoofAngle} required/>
                    </motion.div>}
                    {(!driveValidation && sizeValidation || filteredDriveGroups?.length === 1 && filteredDriveGroups[0]?.name?.toLowerCase() === 'brak') && creatorItem?.chosenGate?.isAutomatic &&
                        <p>{creatorItemDetailsDriveValidation}</p>}
                    {creatorItem?.chosenGate?.isIndustrial &&
                        <p style={{textAlign: 'center'}}>{creatorItemsIndustrailMinValidation1} <strong>350 mm</strong> {creatorItemsIndustrailMinValidation2} <strong>250 mm</strong> {creatorItemsIndustrailMinValidation3}</p>}
                    {creatorItem?.chosenGate?.isAutomatic && !automaticGateValidation && creatorItem?.height?.value && creatorItem?.lintel?.value &&
                        <p style={{textAlign: 'center', marginTop: 10}}>{creatorItemsMinHeightToDriveGates}</p>}
                    {!!minDepth &&
                        <p style={{textAlign: 'center', marginTop: 10}}>{creatorItemDetailsMinDepth}
                            - <strong>{minDepth}mm</strong></p>}
                    {!!minLintel &&
                        <p style={{textAlign: 'center', marginTop: 10}}>{creatorItemsMinLintelValidation1}{creatorItem.lintel.value}mm {creatorItemsMinLintelValidation2}
                            - <strong>{minLintel}mm</strong></p>}
                    {(leadingLintelMin && leadingLintelMax) && !lintelLeadingValidation &&
                        <p style={{textAlign: 'center', marginTop: 10, color: 'red', fontWeight: 700}}>{creatorItemsRangeValidation} {creatorItem.leading.lintelIntervalMin} - {creatorItem.leading.lintelIntervalMax}mm</p>}
                </form>
                <div className="content-bg form-bg show">
                    <img src={img} alt="background image"/>
                </div>
                {/*{updateGateMutation.isLoading && <div className={'content-form--loader'}>*/}
                {/*    <Loader size={'xl'}/>*/}
                {/*</div>}*/}
            </div>
        </motion.div>
    );
};


export default CreatorItemDetail;
